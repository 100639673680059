import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from 'styled-components';
import { AuthProvider } from '../contexts/auth';
import AuthenticatedRoute from '../components/Auth/AuthenticatedRoute';
import ProsumerLockedRoute from '../components/Auth/ProsumerLockedRoute';
import UnauthenthicatedRoute from '../components/Auth/UnauthenthicatedRoute';

import NotFound from '../pages/NotFound';
import {
  appUserUpsellPath,
  authCallbackPath,
  authConfirmEmailPath,
  authPath,
  benefitsPath,
  embeddablePythonPlaygroundPath,
  embeddableSQLPlaygroundPath,
  paymentCallbackPath,
  paymentPath,
  planUpsellPath,
  previewActivityPath,
  previewDashboardPath,
  previewProjectPath,
  projectExamplesPath,
  projectsDashboardPath,
  retroSurveyPath,
  soloActivityPath,
  soloDashboardPath,
  soloOnboardingPath,
  soloProjectPath,
  teamActivityPath,
  teamDashboardPath,
  teamsAuthPath,
  teamSubmitProjectPath,
  unsubscribePath,
} from '../routing';
import theme from '../theme';
import PreviewActivity from './Activity/PreviewActivity';
import ProsumerActivity from './Activity/ProsumerActivity';
import TeamActivity from './Activity/TeamsActivity';
import PreviewDashboard from './Dashboard/PreviewDashboard';
import ProsumerDashboard from './Dashboard/ProsumerDashboard';
import TeamDashboard from './Dashboard/TeamsDashboard';
import Home from './Home';
import Playground from './Playground';
import ProjectExamples from './ProjectExamples';
import RetroSurvey from './RetroSurvey';
import SubmitProject from './SubmitProject';
import ProsumerQueryParamsHandlers from '../components/ProsumerQueryParamsHandlers';
import { AIWorkflowProvider } from '../contexts/ai-workflow';
import * as CrashReporter from '../crash-reporter';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import FullScreenAlert from '../components/Alert/FullScreenAlert';
import { NAVBAR_HEIGHT } from '../components/Navbar';
import { SoloOnboardingProvider } from '../components/SoloOnboarding/context';
import AuthCallback from './AuthCallback';
import ConfirmEmailPage from './ConfirmEmail';
import LoginPage from './Login';
import Payment from './Payment';
import PaymentCallback from './PaymentCallback';
import SoloOnboarding from './SoloOnboarding';
import Benefits from './Benefits';
import Unsubscribe from './Unsubscribe';
import { useEffect } from 'react';
import { trackPageView } from '../analytics';
import AppUserUpsell from './AppUserUpsell';
import EmbeddablePythonPlayground from './PythonPlayground';
import EmbeddableSQLPlayground from './SQLPlayground';
import AppliedProjectActivity from './Activity/AppliedProjectActivity';
import ProjectsDashboard from './ProjectsDashboard';
import AppliedProjectPreviewActivity from './Activity/AppliedProjectPreviewActivity';
import LearningPlanUpsell from './LearningPlanUpsell';

const queryClient = new QueryClient();

export default function Application() {
  return (
    <CrashReporter.ErrorBoundary
      FallbackComponent={() => (
        <FullScreenAlert text="Unfortunately, something went wrong" />
      )}
    >
      <GlobalProviders>
        <Router>
          <ApplicationSwitch />
        </Router>
      </GlobalProviders>
    </CrashReporter.ErrorBoundary>
  );
}

const ApplicationSwitch = () => {
  const history = useHistory();

  useEffect(() => {
    if (
      !window.location.pathname.includes('/public/sql-playground') &&
      !window.location.pathname.includes('/public/python-playground')
    ) {
      trackPageView();
    }
    history.listen(() => trackPageView());
  }, [history]);
  return (
    <Switch>
      <Route path="/public">
        <PublicApp />
      </Route>
      <Route path="/">
        <AuthProvider>
          <AuthenticatedApp />
        </AuthProvider>
      </Route>
    </Switch>
  );
};

function GlobalProviders({ children }: { children: React.ReactNode }) {
  return (
    <>
      <ToastContainer
        theme="dark"
        transition={Zoom}
        position="bottom-right"
        className="enki-toast-container"
        toastClassName="enki-toast"
        progressClassName="enki-toast-progress"
        style={{
          bottom: NAVBAR_HEIGHT,
        }}
      />
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </>
  );
}

function PublicApp() {
  return (
    <Switch>
      <Route exact path="/public/playground">
        <Playground />
      </Route>
      <Route path={embeddablePythonPlaygroundPath}>
        <EmbeddablePythonPlayground />
      </Route>
      <Route path={embeddableSQLPlaygroundPath}>
        <EmbeddableSQLPlayground />
      </Route>
      {/* not found fallback */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

function AuthenticatedApp() {
  return (
    <Switch>
      <UnauthenthicatedRoute exact path={authPath}>
        <LoginPage />
      </UnauthenthicatedRoute>
      <UnauthenthicatedRoute exact path={teamsAuthPath}>
        <LoginPage isTeamsOnboard />
      </UnauthenthicatedRoute>
      <UnauthenthicatedRoute exact path={appUserUpsellPath}>
        <AppUserUpsell />
      </UnauthenthicatedRoute>
      <UnauthenthicatedRoute exact path={authConfirmEmailPath}>
        <ConfirmEmailPage />
      </UnauthenthicatedRoute>
      <Route exact path={authCallbackPath}>
        <AuthCallback />
      </Route>
      <Route exact path="/prosumer/onboarding">
        <Redirect to={{ pathname: '/join', search: window.location.search }} />
      </Route>
      <Route exact path={soloOnboardingPath}>
        <SoloOnboardingProvider>
          <SoloOnboarding />
        </SoloOnboardingProvider>
      </Route>
      <Route exact path={unsubscribePath}>
        <Unsubscribe />
      </Route>
      <Route path="/">
        <AuthenticatedRoutesWithAI />
      </Route>
    </Switch>
  );
}

function AuthenticatedRoutesWithAI() {
  return (
    <AIWorkflowProvider>
      <ProsumerQueryParamsHandlers />
      <Switch>
        <ProsumerLockedRoute exact path={soloDashboardPath}>
          <ProsumerDashboard />
        </ProsumerLockedRoute>
        <ProsumerLockedRoute exact path={soloActivityPath}>
          <ProsumerActivity />
        </ProsumerLockedRoute>
        <ProsumerLockedRoute exact path={soloProjectPath}>
          <AppliedProjectActivity />
        </ProsumerLockedRoute>
        <AuthenticatedRoute exact path={paymentPath}>
          <Payment />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={benefitsPath}>
          <Benefits />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={planUpsellPath}>
          <LearningPlanUpsell />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={paymentCallbackPath}>
          <PaymentCallback />
        </AuthenticatedRoute>
        {/* preview content routes */}
        <AuthenticatedRoute exact path={previewActivityPath}>
          <PreviewActivity />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={previewDashboardPath}>
          <PreviewDashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={previewProjectPath}>
          <AppliedProjectPreviewActivity />
        </AuthenticatedRoute>
        {/* teams routes */}
        <AuthenticatedRoute exact path={teamDashboardPath}>
          <TeamDashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={teamActivityPath}>
          <TeamActivity />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={projectExamplesPath}>
          <ProjectExamples />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={teamSubmitProjectPath}>
          <SubmitProject />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={retroSurveyPath}>
          <RetroSurvey />
        </AuthenticatedRoute>
        {/* <AuthenticatedRoute exact path="/company-planner">
          <CompanyPlanner />
        </AuthenticatedRoute> */}
        <ProsumerLockedRoute exact path="/">
          <Home />
        </ProsumerLockedRoute>
        <ProsumerLockedRoute path={projectsDashboardPath}>
          <ProjectsDashboard />
        </ProsumerLockedRoute>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </AIWorkflowProvider>
  );
}
