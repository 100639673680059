import React, { useEffect } from 'react';

import AuthenticatedRoute from './AuthenticatedRoute';
import { useGetProsumerProfile } from '../../hooks/use-hydra';
import { benefitsPath, planUpsellPath } from '../../routing';
import { useHistory } from 'react-router-dom';
import FullScreenLoader from '../Loader/FullScreenLoader';
const ProsumerLockedRoute = ({
  path,
  children,
  ...rest
}: {
  path: string;
  children: React.ReactNode;
  exact?: boolean;
}) => {
  const { value: prosumerProfile, loading } = useGetProsumerProfile();
  const history = useHistory();
  useEffect(() => {
    if (
      prosumerProfile &&
      !prosumerProfile.learnMode.hasAccess &&
      !window.location.pathname.includes('/payment-callback') &&
      !window.location.pathname.includes('/payment') &&
      !window.location.pathname.includes(benefitsPath)
    ) {
      history.push(planUpsellPath);
    }
  }, [prosumerProfile, history]);

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <AuthenticatedRoute path={path} {...rest}>
      {children}
    </AuthenticatedRoute>
  );
};

export default ProsumerLockedRoute;
