import { useActor } from '@xstate/react';
import React, { ChangeEvent } from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import styled, { useTheme } from 'styled-components';
import {
  CenteredContainer,
  Header,
  MainPillButton,
  ReadableContainer,
  ButtonsWrapper,
  Label,
  StyledInput,
  BackgroundContainer,
  CTAButton,
} from './common';
import { TOPICS_DATA } from '../config';
import { PExtraSmall } from '../../elements/Text';
import { LightTabs } from '../../elements/Tabs';
import { MultiLineInput } from '../../elements/Input';
import autoAnimate from '@formkit/auto-animate';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { postTeamsInterestForm } from '../../../hydra';
import * as Analytics from '../../../analytics';

const ButtonSubtitle = styled(PExtraSmall)`
  color: ${({ theme }) => theme.colors.textRegular};
  margin-top: 4px;
  bottom-padding: 0;
  font-weight: 400;
  padding-bottom: 0;
`;

export default function AskTopic() {
  const [activeTab, setActiveTab] = React.useState('Solo');
  const autoAnimParent = React.useRef(null);
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_TOPIC,
    state.context
  );

  React.useEffect(() => {
    autoAnimParent.current &&
      autoAnimate(autoAnimParent.current, {
        duration: 500,
        easing: 'ease-in-out',
      });
  }, [autoAnimParent]);

  return (
    <BackgroundContainer>
      <ReadableContainer>
        <CenteredContainer ref={autoAnimParent}>
          <LightTabs
            options={['Solo', 'Teams']}
            selectedOptionIndex={
              activeTab === 'Solo' ? 0 : activeTab === 'Teams' ? 1 : 0
            }
            onClick={() =>
              setActiveTab(activeTab === 'Solo' ? 'Teams' : 'Solo')
            }
          />
          {activeTab === 'Solo' && <SoloComponent />}
          {activeTab === 'Teams' && <TeamsComponent />}
        </CenteredContainer>
      </ReadableContainer>
    </BackgroundContainer>
  );
}

function SoloComponent() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  return (
    <>
      <Header>What do you want to learn first?</Header>
      <ButtonsWrapper>
        {Object.entries(TOPICS_DATA).map(([key, value]) => (
          <MainPillButton
            key={key}
            label={value.label}
            iconUrl={value.iconUrl}
            isSelected={state.context.topic === value.topic}
            onClick={() => {
              send({
                type: EVENTS.SELECT_TOPIC,
                topic: value.topic,
              });
              send({
                type: EVENTS.CONTINUE_ASK_TOPIC,
              });
            }}
          >
            <ButtonSubtitle>{value.subtitle}</ButtonSubtitle>
          </MainPillButton>
        ))}
      </ButtonsWrapper>
    </>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledMultilineInput = styled(MultiLineInput)`
  margin: 0;
  padding-bottom: 0;
  & > div:first-child {
    padding: 12px 16px;

    opacity: 1;
    border-radius: 12px;
  }
`;

const TeamsFormWrapper = styled(Column)`
  max-width: 600px;
`;

function TeamsComponent() {
  const [email, setEmail] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [teamSize, setTeamSize] = React.useState('');
  const [extraInfo, setExtraInfo] = React.useState('');

  const theme = useTheme();

  const submitFormMutation = useMutation({
    mutationFn: async () => {
      try {
        await postTeamsInterestForm({
          workEmail: email,
          company,
          teamSize,
          extraInfo,
        });
      } catch (error) {
        toast.error('Sorry, something went wrong.');
      }
    },
  });

  return (
    <TeamsFormWrapper>
      <Header>Enki enables fully-custom coaching team-wide</Header>
      {!submitFormMutation.isSuccess ? (
        <Label>Get in touch to learn more!</Label>
      ) : (
        <Label>Thank you! We’ll be in touch soon.</Label>
      )}
      {!submitFormMutation.isSuccess && (
        <>
          <Column>
            <StyledInput
              type="text"
              placeholder="Work email"
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <Row>
              <StyledInput
                type="text"
                placeholder="Company"
                value={company}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCompany(e.target.value)
                }
              />
              <StyledInput
                type="text"
                placeholder="Num. of Employees"
                value={teamSize}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setTeamSize(e.target.value)
                }
              />
            </Row>
            <StyledMultilineInput
              placeholder="Tell us more"
              value={extraInfo}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setExtraInfo(e.target.value)
              }
              minRows={2}
              maxRows={2}
            />
          </Column>
          <CTAButton
            label="Get in Touch!"
            isDisabled={!email || !company || !teamSize}
            backgroundColor={theme.colors.bgPage}
            isLoading={submitFormMutation.isLoading}
            onClick={submitFormMutation.mutate}
          />
        </>
      )}
    </TeamsFormWrapper>
  );
}
