import { useActor } from '@xstate/react';
import React from 'react';
import { GOALS, TOPICS, TOPICS_DATA } from '../config';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  CenteredContainer,
  CTAButton,
  Header,
  HighlightSpan,
  ReadableContainer,
  MainImage,
  Label,
} from './common';
import * as Analytics from '../../../analytics';

const topicGoalMap: Record<string, Record<string, string>> = {
  [GOALS.FIND_A_JOB.value]: {
    [TOPICS.PYTHON]:
      'Did you know? In 2023, over **80%** of data science job postings mentioned Python as a required skill.',
    [TOPICS.SQL]:
      'Did you know? SQL appears in over **50%** of all tech job listings, making it a gateway skill for many roles.',
    // [TOPICS.GEN_AI]:
    //   'Did you know? Roles requiring generative AI skills have grown by over **75%** in the past year.',
    [TOPICS.SHEETS]:
      'Did you know? Proficiency in spreadsheets is requested in over **60%** of entry-level business analyst roles.',
    [TOPICS.DATA_LITERACY]:
      'Did you know? **70%** of companies consider data analysis skills essential for new hires.',
    [TOPICS.TABLEAU]:
      'Did you know? Tableau proficiency is highlighted in nearly **30%** of data analyst job postings.',
    [TOPICS.POWER_BI]:
      'Did you know? Power BI skills are requested in over **25%** of business intelligence job openings.',
    [TOPICS.LOOKER]:
      'Did you know? Familiarity with Looker is appearing in about **15%** of data engineer and analyst job postings.',
  },
  [GOALS.SWITCH_TECHNICAL_ROLE.value]: {
    [TOPICS.PYTHON]:
      'Did you know? Professionals adding Python to their skillset have a **50% faster** path into technical roles.',
    [TOPICS.SQL]:
      'Did you know? Mastering SQL can shorten the time-to-hire by nearly **20%** when moving into technical positions.',
    // [TOPICS.GEN_AI]:
    //   'Did you know? Professionals who add generative AI to their skill set can pivot into tech roles **25% quicker**.',
    [TOPICS.SHEETS]:
      'Did you know? Strong spreadsheet skills can ease your transition into data-centric technical roles by **20%**.',
    [TOPICS.DATA_LITERACY]:
      'Did you know? Learning data analysis can help you shift into analytical tech roles **30% faster**.',
    [TOPICS.TABLEAU]:
      'Did you know? Learning Tableau can expedite your move into data visualization roles by **25%**.',
    [TOPICS.POWER_BI]:
      'Did you know? Learning Power BI can shorten the transition into business intelligence roles by **20%**.',
    [TOPICS.LOOKER]:
      'Did you know? Adding Looker to your skill set can simplify the move into data analytics roles by **15%**.',
  },
  [GOALS.PREPARE_FOR_INTERVIEWS.value]: {
    [TOPICS.PYTHON]:
      'Did you know? Python questions are among the top **3** most common topics in technical interviews.',
    [TOPICS.SQL]:
      'Did you know? SQL is one of the top **5** most frequently tested skills in technical interviews.',
    // [TOPICS.GEN_AI]:
    //   'Did you know? Generative AI questions are appearing in **1 out of every 5** advanced technical interviews.',
    [TOPICS.SHEETS]:
      'Did you know? Spreadsheet exercises appear in about **1 in 4** data-related interview assessments.',
    [TOPICS.DATA_LITERACY]:
      'Did you know? Data analysis case studies appear in over **50%** of technical interview processes.',
    [TOPICS.TABLEAU]:
      'Did you know? Tableau-based visualization tasks appear in about **1 in 5** data-related interviews.',
    [TOPICS.POWER_BI]:
      'Did you know? Power BI scenario questions appear in around **1 in 6** BI-related interviews.',
    [TOPICS.LOOKER]:
      'Did you know? Looker-based data exploration tasks now show up in about **1 in 10** analytics interviews.',
  },
  [GOALS.OPTIMIZE_SKILLS.value]: {
    [TOPICS.PYTHON]:
      'Did you know? Automating tasks with Python can save professionals up to **2 hours** of manual work daily.',
    [TOPICS.SQL]:
      'Did you know? Using SQL for data queries can reduce manual data processing time by up to **40%**.',
    // [TOPICS.GEN_AI]:
    //   'Did you know? Using generative AI can speed up content creation and coding tasks by up to **50%**.',
    [TOPICS.SHEETS]:
      'Did you know? Using advanced spreadsheet formulas can cut daily data processing time by up to **40%**.',
    [TOPICS.DATA_LITERACY]:
      'Did you know? Applying proper data analysis techniques can reduce decision-making time by up to **25%**.',
    [TOPICS.TABLEAU]:
      'Did you know? Using Tableau’s visual dashboards can reduce time spent on manual reports by up to **40%**.',
    [TOPICS.POWER_BI]:
      'Did you know? Power BI dashboards can reduce reporting workload by up to **35%**.',
    [TOPICS.LOOKER]:
      'Did you know? Looker’s self-service analytics can cut the time waiting for data reports by up to **30%**.',
  },
  [GOALS.ACCELERATE_CAREER.value]: {
    [TOPICS.PYTHON]:
      'Did you know? Professionals who master Python often earn salaries **15% higher** than their peers.',
    [TOPICS.SQL]:
      'Did you know? Professionals with SQL skills can earn up to **10% more** on average than those without.',
    // [TOPICS.GEN_AI]:
    //   'Did you know? Leaders in generative AI often command salaries **20% above** the industry average.',
    [TOPICS.SHEETS]:
      'Did you know? Professionals with expert spreadsheet skills are **15% more likely** to be promoted into analyst roles.',
    [TOPICS.DATA_LITERACY]:
      'Did you know? Professionals who excel in data analysis see a **10–15% increase** in promotion rates.',
    [TOPICS.TABLEAU]:
      'Did you know? Mastering Tableau can boost your odds of securing a data leadership position by **15%**.',
    [TOPICS.POWER_BI]:
      'Did you know? Gaining Power BI expertise can lead to roles with an average **10–15%** salary increase.',
    [TOPICS.LOOKER]:
      'Did you know? Professionals who master Looker often qualify for roles that pay **10% more** on average.',
  },
  [GOALS.BUILD_LEARNING_HABIT.value]: {
    [TOPICS.PYTHON]:
      'Did you know? Consistent Python practice improves problem-solving skills by up to **30%**.',
    [TOPICS.SQL]:
      'Did you know? Regularly practicing SQL queries can improve data interpretation skills by over **25%**.',
    // [TOPICS.GEN_AI]:
    //   'Did you know? Regular experimentation with generative AI can improve creative problem-solving by **30%**.',
    [TOPICS.SHEETS]:
      'Did you know? Practicing spreadsheet tasks regularly can improve your data literacy by around **25%**.',
    [TOPICS.DATA_LITERACY]:
      'Did you know? Consistent practice with data analysis techniques can improve analytical thinking by **40%**.',
    [TOPICS.TABLEAU]:
      'Did you know? Practicing with Tableau regularly can improve your ability to present insights effectively by **30%**.',
    [TOPICS.POWER_BI]:
      'Did you know? Consistent hands-on practice with Power BI improves data storytelling skills by **25%**.',
    [TOPICS.LOOKER]:
      'Did you know? Regularly exploring data in Looker can improve your data interpretation skills by **20%**.',
  },
  [GOALS.EXPLORE_TEAMS.value]: {
    [TOPICS.PYTHON]:
      'Did you know? Teams who adopt Python for data workflows report a **25% reduction** in project turnaround times.',
    [TOPICS.SQL]:
      'Did you know? Teams proficient in SQL often deliver data insights **30% faster**.',
    // [TOPICS.GEN_AI]:
    //   'Did you know? Teams integrating generative AI tools cut their project delivery times by about **35%**.',
    [TOPICS.SHEETS]:
      'Did you know? Teams proficient in spreadsheet automation report a **30% reduction** in reporting errors.',
    [TOPICS.DATA_LITERACY]:
      'Did you know? Teams trained in data analysis deliver insights **20% faster**.',
    [TOPICS.TABLEAU]:
      'Did you know? Teams using Tableau share insights **20% faster** and more effectively.',
    [TOPICS.POWER_BI]:
      'Did you know? Teams leveraging Power BI often identify key business insights **30% faster**.',
    [TOPICS.LOOKER]:
      'Did you know? Teams using Looker for unified metrics reduce data discrepancies by **25%**.',
  },
  other: {
    [TOPICS.PYTHON]:
      'Did you know? Python’s popularity rose by over **20%** in the past year, making it a future-proof skill.',
    [TOPICS.SQL]:
      'Did you know? SQL has remained one of the top **3** data skills for over a decade, showing its enduring value.',
    // [TOPICS.GEN_AI]:
    //   'Did you know? Interest in generative AI learning resources grew by **200%** last year alone.',
    [TOPICS.SHEETS]:
      'Did you know? Spreadsheets remain a top tool for **80%** of companies managing their operational data.',
    [TOPICS.DATA_LITERACY]:
      'Did you know? Demand for strong data analysis skills has risen over **50%** in the last five years.',
    [TOPICS.TABLEAU]:
      'Did you know? Tableau’s user base has grown by over **60%** in the last three years, reflecting its industry importance.',
    [TOPICS.POWER_BI]:
      'Did you know? The adoption of Power BI has grown steadily, with a **25% increase** in enterprise use last year.',
    [TOPICS.LOOKER]:
      'Did you know? Looker’s user adoption doubled in recent years, reflecting growing market demand.',
  },
};
export default function PostAskGoal() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const { goal, topic } = state.context;

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_POST_ASK_GOAL,
    state.context
  );

  if (!topic) {
    throw new Error('Topic is required to save onboarding answers');
  }

  if (!goal) {
    throw new Error('Goal is required to save onboarding answers');
  }
  const goalRelatedMessage = Object.keys(topicGoalMap).includes(goal)
    ? topicGoalMap[goal][topic]
    : topicGoalMap.other[topic];

  const goalRelatedMessageParts = goalRelatedMessage.split('**'); // Odds are bolded, evens are not

  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>
          Learning <HighlightSpan>{TOPICS_DATA[topic].label}</HighlightSpan> is
          a great choice!
        </Header>
        <MainImage
          src={'https://img.enkipro.com/1ec92bba65c153ba1736618014dfd4b8.png'}
        />
        <Label>
          {goalRelatedMessageParts.map((msg, ind) =>
            ind % 2 == 0 ? <span key={msg}>{msg}</span> : <b key={msg}>{msg}</b>
          )}
        </Label>

        <CTAButton
          onClick={() => send(EVENTS.CONTINUE_POST_ASK_GOAL)}
          label="Continue"
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
