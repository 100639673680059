export const TOPICS = {
  PYTHON: 'PYTHON',
  SHEETS: 'SHEETS',
  SQL: 'SQL',
  DATA_LITERACY: 'DATA_LITERACY',
  LOOKER: 'LOOKER',
  TABLEAU: 'TABLEAU',
  POWER_BI: 'POWER_BI',
  // GEN_AI: 'GEN_AI',
};

export const PROFILES = {
  TECHNICAL: {
    value: 'TECHNICAL',
    label: 'Technical',
  },
  NON_TECHNICAL: {
    value: 'NON_TECHNICAL',
    label: 'Non-technical',
  },
  MANAGER: {
    value: 'MANAGER/EXECUTIVE',
    label: 'Manager / Exec',
  },
  STUDENT: {
    value: 'STUDENT',
    label: 'Student',
  },
  OTHER: {
    value: 'OTHER',
    label: 'Other',
  },
};
export const ROLES = {
  ENGINEER: {
    value: 'SOFTWARE_ENGINEER',
    label: 'Software Engineer',
  },
  DATA_ANALYST: {
    value: 'DATA_ANALYST',
    label: 'Data Analyst',
  },
  DESIGNER: {
    value: 'DESIGNER',
    label: 'Designer',
  },
  MARKETING: {
    value: 'MARKETING',
    label: 'Marketing',
  },
  PRODUCT_MANAGER: {
    value: 'PRODUCT_MANAGER',
    label: 'Product Manager',
  },
  SALES: {
    value: 'SALES',
    label: 'Sales',
  },
  FINANCE: {
    value: 'FINANCE',
    label: 'Finance',
  },
  OPERATIONS: {
    value: 'OPERATIONS',
    label: 'Operations',
  },
  SUPPORT: {
    value: 'SUPPORT',
    label: 'Support',
  },
  QA: {
    value: 'QA',
    label: 'QA',
  },
  OTHER: {
    value: 'OTHER',
    label: 'Other',
  },
};

export const GOAL_CATEGORIES = {
  OPTIMIZE: 'OPTIMIZE',
  START: 'START',
};
export const GOALS = {
  OPTIMIZE_SKILLS: {
    value: 'OPTIMIZE_SKILLS',
    label: 'Be more productive at work or school',
    category: GOAL_CATEGORIES.OPTIMIZE,
  },
  SWITCH_TECHNICAL_ROLE: {
    value: 'SWITCH_TECHNICAL_ROLE',
    label: 'Switch to a technical role',
    category: GOAL_CATEGORIES.START,
  },
  PREPARE_FOR_INTERVIEWS: {
    value: 'PREPARE_FOR_INTERVIEWS',
    label: 'Prepare for interviews',
    category: GOAL_CATEGORIES.START,
  },
  ACCELERATE_CAREER: {
    value: 'ACCELERATE_CAREER',
    label: 'Advance my career',
    category: GOAL_CATEGORIES.OPTIMIZE,
  },
  FIND_A_JOB: {
    value: 'FIND_A_JOB',
    label: 'Find a job',
    category: GOAL_CATEGORIES.START,
  },

  BUILD_LEARNING_HABIT: {
    value: 'BUILD_LEARNING_HABIT',
    label: 'Build a learning habit',
    category: GOAL_CATEGORIES.OPTIMIZE,
  },
  EXPLORE_TEAMS: {
    value: 'EXPLORE_TEAMS',
    label: "I'm testing Enki for my team",
    category: GOAL_CATEGORIES.OPTIMIZE,
  },
  OTHER: {
    value: 'OTHER',
    label: 'Other',
    category: GOAL_CATEGORIES.OPTIMIZE,
  },
};

export const TIME_EFFORT = {
  UNSURE: {
    value: 'UNSURE',
    label: 'Unsure',
  },
  MINUTES_5_10: {
    value: 'MINUTES_5_10',
    label: '5-10 minutes / day',
  },
  MINUTES_20_30: {
    value: 'MINUTES_20_30',
    label: '20-30 minutes / day',
  },
  MORE: {
    value: 'MORE',
    label: 'More',
  },
};
export const COMPLETE_BEGINNER_PLACEMENT_OPTION = 'complete_beginner';
export const ADVANCED_PLACEMENT_OPTION = 'advanced';
export const THEORY_PREFERENCE_OPTIONS = {
  THEORY: { value: 'theory', label: 'I want a theory refresher' },
  PRACTICE: { value: 'practice', label: 'Dive into practice' },
};

export const APPLICATIONS = {
  DATA_CLEANING: {
    value: 'DATA_CLEANING',
    label: 'Data Cleaning',
  },
  DATA_ANALYSIS: {
    value: 'DATA_ANALYSIS',
    label: 'Data Analysis',
  },
  DATA_VIZ: {
    value: 'DATA_VIZ',
    label: 'Data Visualization',
  },
  TIME_SERIES: {
    value: 'TIME_SERIES',
    label: 'Time Series',
  },
  SOFTWARE_ENGINEERING: {
    value: 'SOFTWARE_ENGINEERING',
    label: 'Software Engineering',
  },
  MATPLOTLIB: {
    value: 'MATPLOTLIB',
    label: 'Matplotlib',
  },
  PANDAS: {
    value: 'PANDAS',
    label: 'Pandas',
  },
  CTES: {
    value: 'CTES',
    label: 'CTEs',
  },
  WINDOW_FUNCTIONS: {
    value: 'WINDOW_FUNCTIONS',
    label: 'Window Functions',
  },
  SUBQUERIES: {
    value: 'SUBQUERIES',
    label: 'Subqueries',
  },
};

export const TOPICS_DATA = {
  [TOPICS.PYTHON]: {
    label: 'Python',
    subtitle: 'Coding and data analysis',
    faIcon: 'fa-solid fa-code',
    topic: TOPICS.PYTHON,
    iconUrl: 'https://img.enkipro.com/dbe4a7588876258aef7966c62dc67e68.png',
    coloredIconUrl:
      'https://img.enkipro.com/02f06ab40ceb0797206fdade3903f009.png',
    placementTest: {
      header: 'Choose all that are true about your Python knowledge',
      options: [
        {
          text: 'Beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
        },
        {
          text: 'I know some Python.',
          value: 'know_some_python',
        },
        {
          text: "I'm confident writing Python programs",
          value: ADVANCED_PLACEMENT_OPTION,
        },
      ],
    },
  },
  [TOPICS.SQL]: {
    label: 'SQL',
    subtitle: 'Managing and extracting data',
    faIcon: 'fa-solid fa-database',
    topic: TOPICS.SQL,
    iconUrl: 'https://img.enkipro.com/80a6a34160ca3ad5e31ccba0cdcb653b.png',
    placementTest: {
      header: 'What is your experience with SQL?',
      options: [
        {
          text: 'Beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
        },
        {
          text: 'I know some SQL.',
          value: 'know_some_sql',
        },
        {
          text: "I'm comfortable writing SQL queries.",
          value: 'comfortable_writing_sql',
        },
        {
          text: 'I can handle complex SQL easily.',
          value: ADVANCED_PLACEMENT_OPTION,
        },
      ],
    },
  },
  /*
  [TOPICS.GEN_AI]: {
    label: 'Generative AI',
    subtitle: 'Task automation',
    faIcon: 'fa-solid fa-robot',
    topic: TOPICS.GEN_AI,
    iconUrl: 'https://img.enkipro.com/a30c0770136afec29306d5728d17fb3d.png',
    placementTest: {
      header: 'What is your experience with Generative AI?',
      options: [
        {
          text: 'Beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
        },
        {
          text: "I've wrote a few prompts before.",
          value: 'know_some_gen_ai',
        },
        {
          text: "I'm comfortable writing complex prompts.",
          value: ADVANCED_PLACEMENT_OPTION,
        },
      ],
    },
    applications: [
      'automating workflows',
      'copywriting',
      'finance',
      'marketing',
      'Other',
    ],
  },
  */
  [TOPICS.SHEETS]: {
    label: 'Spreadsheets',
    subtitle: 'Working with table data',
    faIcon: 'fa-solid fa-file-spreadsheet',
    topic: TOPICS.SHEETS,
    iconUrl: 'https://img.enkipro.com/3b081c6024bde04a199fbbb0416a0e14.png',
    placementTest: {
      header: 'What is your experience with Spreadsheets?',
      options: [
        {
          text: 'Beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
        },
        {
          text: 'I can do basic tasks like creating tables or formulas.',
          value: 'know_some_sheets',
        },
        {
          text: "I'm comfortable with spreadsheets.",
          value: ADVANCED_PLACEMENT_OPTION,
        },
      ],
    },
  },
  [TOPICS.DATA_LITERACY]: {
    label: 'Data Analytics',
    subtitle: 'Data best practices',
    faIcon: 'fa-solid fa-chart-line',
    topic: TOPICS.DATA_LITERACY,
    iconUrl: 'https://img.enkipro.com/91c86556f4b8645ea64753c8301ddd95.png',
    placementTest: {
      header: "What's your experience with data analysis?",
      options: [
        {
          text: 'Beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
        },
        {
          text: "I've worked with data a little, like making basic observations.",
          value: 'know_some_data_analysis',
        },
        {
          text: "I'm confident analyzing and interpreting data effectively.",
          value: ADVANCED_PLACEMENT_OPTION,
        },
      ],
    },
  },
  [TOPICS.TABLEAU]: {
    label: 'Tableau',
    subtitle: 'Visual Data Analysis',
    faIcon: 'fa-solid fa-chart-bar',
    topic: TOPICS.TABLEAU,
    iconUrl: 'https://img.enkipro.com/d4807185a2a162aec22f868044b47585.png',
    placementTest: {
      header: "What's your experience with Tableau?",
      options: [
        {
          text: 'Beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
        },
        {
          text: "I've created a few simple visualizations",
          value: 'know_some_tableau',
        },
        {
          text: "I'm confident building dashboards in Tableau.",
          value: ADVANCED_PLACEMENT_OPTION,
        },
      ],
    },
  },
  [TOPICS.POWER_BI]: {
    label: 'Power BI',
    subtitle: 'Business Intelligence',
    faIcon: 'fa-solid fa-chart-pie',
    topic: TOPICS.POWER_BI,
    iconUrl: 'https://img.enkipro.com/f470316005d0ee81790c12de3edb6820.png',
    placementTest: {
      header: "What's your experience with Power BI?",
      options: [
        {
          text: 'Beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
        },
        {
          text: "I've built a few simple reports or dashboards.",
          value: 'know_some_power_bi',
        },
        {
          text: "I'm comfortable creating complex Power BI dashboards.",
          value: ADVANCED_PLACEMENT_OPTION,
        },
      ],
    },
  },
  [TOPICS.LOOKER]: {
    label: 'Looker',
    subtitle: 'Explore business data',
    faIcon: 'fa-solid fa-search',
    iconUrl: 'https://img.enkipro.com/071e973f2b87d1f37e6eb60c20692926.png',
    topic: TOPICS.LOOKER,
    placementTest: {
      header: "What's your experience with Looker?",
      options: [
        {
          text: 'Beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
        },
        {
          text: "I've explored some Looker reports or data.",
          value: 'know_some_looker',
        },
        {
          text: "I'm confident creating reports in Looker.",
          value: ADVANCED_PLACEMENT_OPTION,
        },
      ],
    },
  },
};
