import { useActor } from '@xstate/react';
import React, { ChangeEvent, useState } from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS, STATES } from '../state-machine';
import {
  CenteredContainer,
  CTAButton,
  Header,
  Label,
  ReadableContainer,
  SmallReadableSection,
  StyledInput,
} from './common';
import * as Analytics from '../../../analytics';

export default function GetResults() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const isLoading = state.matches(STATES.CHECK_EMAIL_AVAILABILITY);

  const email = state.context.email;
  const [hasAttemptedSubmission, setHasAttemptedSubmission] = useState(false);
  const isSubmittedEmailValid =
    email && email.includes('@') && email.includes('.');

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_EMAIL,
    state.context
  );

  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>Enter your email to get your personalized program</Header>
        <SmallReadableSection>
          <StyledInput
            value={email || ''}
            type="email"
            errorMessage={
              !isSubmittedEmailValid && hasAttemptedSubmission
                ? 'Please enter a valid email'
                : ''
            }
            placeholder="Enter your email"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              send({
                type: EVENTS.ADD_EMAIL,
                email: `${e.target.value}`,
              });
            }}
          />
        </SmallReadableSection>

        <Label>
          Your AI mentor will also communicate with you via this email.
        </Label>

        <CTAButton
          isDisabled={!state?.context?.email} // TODO: proper email check
          onClick={
            isSubmittedEmailValid
              ? () => send(EVENTS.CONTINUE_GET_RESULTS)
              : () => setHasAttemptedSubmission(true)
          }
          label="Get My Program"
          isLoading={isLoading}
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
