import { useActor } from '@xstate/react';
import React from 'react';
import styled from 'styled-components';
import { P, PSmall } from '../../elements/Text';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import { CTAButton, Header, Label, LargeQuote } from './common';
import * as Analytics from '../../../analytics';
import { TOPICS, TOPICS_DATA } from '../config';
import { useMedia } from 'react-use';

const PageWrapper = styled.div`
  display: block;
  width: 100%;
  overflow: auto;
  padding-top: 72px;
  padding-bottom: 72px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};

  @media only screen and (max-width: 600px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 12px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

const QuoteWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  padding: 32px;
`;

const GreenHighlightSpan = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

export function BenefitsContent({
  onNext,
  quote,
}: {
  onNext: () => void;
  quote?: string;
}) {
  return (
    <PageWrapper>
      <Column style={{ gap: 32 }}>
        <Section>
          <Label>
            Enki is not a tutorial. It's your personalized automated mentor.
          </Label>
          <Header>
            This is how <GreenHighlightSpan>Enki</GreenHighlightSpan> membership
            works
          </Header>
        </Section>
        <BenefitsGrid />
        <QuoteWrapper>
          <Section>
            <HeroQuote quote={quote} />
          </Section>
        </QuoteWrapper>
        <Section>
          <CTAButton onClick={onNext} label="Sounds good, continue!" />
        </Section>
      </Column>
    </PageWrapper>
  );
}
export default function Benefits() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_UPSELL_BENEFITS,
    state.context
  );

  return (
    <BenefitsContent
      onNext={() => send(EVENTS.CONTINUE_BENEFITS)}
      quote={getPersonalizedQuote({ topic: state.context.topic })}
    />
  );
}

function getPersonalizedQuote({ topic }: { topic: string | null }) {
  if (topic === TOPICS.PYTHON) {
    return 'Enki’s program is by far the most effective way to learn how to code.';
  }

  if (topic) {
    return `Enki’s program is by far the most effective way to learn ${TOPICS_DATA[topic].label}.`;
  }

  return 'Enki’s program is by far the most effective way to learn.';
}

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 800px;
  margin: 0 auto;
  max-width: 100%;

  @media screen and (max-width: 768px) {
    gap: 32px;
  }
`;

const GridItem = styled.div`
  text-align: left;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const GridTitle = styled(P)`
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.textActive};
  transition: text-shadow 1s ease;
  &:hover {
    text-shadow: 0 0 10px #fffa;
  }
`;

const GridDescription = styled(PSmall)`
  padding: 0;
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  transition: text-shadow 1s ease;

  &:hover {
    text-shadow: 0 0 6px #fffa;
  }
`;

const GridImage = styled.img`
  width: 280px;
  padding: 16px 0;
  margin: 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

function HeroQuote({
  quote = 'Enki’s program is by far the most effective way to learn.',
}: {
  quote?: string;
}) {
  return (
    <LargeQuote
      quote={quote}
      author="Jeff Feng"
      position="AI & Data Lead @ Google, Airbnb"
      avatarURL="https://img.enkipro.com/017dbbd66a01366c7ad6ada5ddddd322.png"
    />
  );
}

const gridContents = [
  {
    title: 'You complete interactive exercises',
    subtitle:
      'All exercises and projects are tied to your custom learning plan.',
    imageUrl: 'https://img.enkipro.com/c23ad6901b5d0136fdba39ef4d1596d4.png',
  },

  {
    title: 'Enki is by your side 24/7',
    subtitle:
      'Ask Enki AI for hints, dig into a topic with it, discuss anything!',
    imageUrl: 'https://img.enkipro.com/e8b3e0f13c8be28a53b90c34a3dc19fa.png',
  },

  {
    title: 'Enki reviews all your work',
    subtitle:
      "You'll get custom reviews with key takeaways and improvement areas.",
    imageUrl: 'https://img.enkipro.com/455e950c8d6c922bef6526f7e0c8bf57.png',
  },

  {
    title: 'Enki adapts your learning plan',
    subtitle:
      'Your learning plan is adapted in real-time as you make progress.',
    imageUrl: 'https://img.enkipro.com/38e3f48ab7aadcf4958e7fee4d31bf3c.png',
  },
  {
    title: 'Support from experienced developers',
    subtitle: (
      <span>
        <i>(Included as a special deal - $350/year value!)</i> <br />
        Our experts answer all your questions, and help you with your career in
        general. `
      </span>
    ),
    imageUrl: 'https://img.enkipro.com/b84f8df6635ed6c95c0842fe5c92c32c.png',
  },
];

function BenefitsGrid() {
  return (
    <GridWrapper>
      {gridContents.map((content, ind) => (
        <BenefitsGridRow {...content} ind={ind} />
      ))}
    </GridWrapper>
  );
}

function StepCircleColumn({ ind }: { ind: number }) {
  return (
    <StepCircleContainer>
      <VerticalLine shouldShow={ind > 0} />
      <StepCircle>{ind + 1}</StepCircle>
      <VerticalLine shouldShow={ind < gridContents.length - 1} />
    </StepCircleContainer>
  );
}

const StepCircleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const VerticalLine = styled.div<{ shouldShow: boolean }>`
  background-color: ${({ shouldShow }) =>
    shouldShow ? 'rgba(113, 113, 122, 0.78)' : 'transparent'};
  width: 2px;
  flex-grow: 1;

  @media screen and (max-width: 768px) {
    background-color: transparent;
  }
`;
const StepCircle = styled.p`
  background-color: ${({ theme }) => theme.colors.bgActive};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.bgDefault};
  width: 40px;
  height: 40px;
  padding: 0;

  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  box-shadow: 0 0 40px 0px rgba(161, 161, 170, 0.4);
  flex-shrink: 0;
`;

function BenefitsGridRow({
  title,
  subtitle,
  imageUrl,
  ind,
}: {
  title: string;
  subtitle: React.ReactNode;
  imageUrl: string;
  ind: number;
}) {
  const isMobile = useMedia('(max-width: 768px)');
  return (
    <GridItem>
      {ind % 2 == 1 || isMobile ? (
        <Row style={{ justifyContent: 'space-between' }}>
          <GridImage src={imageUrl} />
          <StepCircleColumn ind={ind} />
          <Column style={{ width: '280px' }}>
            <GridTitle>{title}</GridTitle>
            <GridDescription>{subtitle}</GridDescription>
          </Column>
        </Row>
      ) : (
        <Row style={{ justifyContent: 'space-between' }}>
          <Column style={{ width: '280px' }}>
            <GridTitle>{title}</GridTitle>
            <GridDescription>{subtitle}</GridDescription>
          </Column>
          <StepCircleColumn ind={ind} />
          <GridImage src={imageUrl} />
        </Row>
      )}
    </GridItem>
  );
}
