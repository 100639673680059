// import { set } from 'es-cookie';
import { Auth0DecodedHash } from 'auth0-js';
import { trackPixelEvent } from '../../facebook';
import {
  checkEmailAvailability,
  createProsumerLearnModePaymentIntent,
  createProsumerLearnModeSubscription,
  finishSoloOnboarding,
} from '../../hydra';
import { saveSoloOnboarding } from '../../hydra';
import {
  APPLICATIONS,
  GOAL_CATEGORIES,
  GOALS,
  PROFILES,
  ROLES,
  THEORY_PREFERENCE_OPTIONS,
  TIME_EFFORT,
  TOPICS_DATA,
} from './config';
import { ContextType, OnboardQuestion } from './state-machine';

export enum SERVICES {
  HANDLE_PAYMENT_CALLBACK = 'HANDLE_PAYMENT_CALLBACK',
  HANDLE_AUTH_CALLBACK = 'HANDLE_SIGNUP_CALLBACK',
  INIT_PAYMENT = 'INIT_PAYMENT',
  SAVE_ONBOARDING_ANSWERS = 'SAVE_ONBOARDING_ANSWERS',
  CHECK_EMAIL_AVAILABILITY = 'CHECK_EMAIL_AVAILABILITY',
}

export const parameterisedServices = {
  [SERVICES.HANDLE_AUTH_CALLBACK]:
    ({
      handleAuthCallback,
      getTokenSilently,
    }: {
      handleAuthCallback: () => Promise<Auth0DecodedHash | null>;
      getTokenSilently: () => Promise<string>;
    }) =>
    async () => {
      await handleAuthCallback();
      const token = await getTokenSilently();
      const result = await finishSoloOnboarding(token);
      return {
        partnerCode: result.partnerSignupCode,
        partnerWelcomeMessage: result.partnerWelcomeMessage,
        hasPartnerCode: result.hasPartnerCode,
      };
    },
};

export default {
  [SERVICES.HANDLE_PAYMENT_CALLBACK]: async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const paymentIntentId = urlParams.get('payment_intent');

    if (!paymentIntentId)
      throw new Error('Setup intent not found in query params');

    const { email, isTrial, currency, value, metaEventId } =
      await createProsumerLearnModeSubscription({
        paymentIntentId,
      });

    if (isTrial) {
      trackPixelEvent(
        'StartTrial',
        {
          currency,
          value,
        },
        metaEventId
      );
    }

    return { email };
  },

  [SERVICES.INIT_PAYMENT]: async (context: ContextType) => {
    // call hydra api to create stripe intent and apply discount code
    if (!context.email) {
      throw new Error('Sorry, we need your email first.');
    }

    const { paymentIntentClientSecret, discountPercentage } =
      await createProsumerLearnModePaymentIntent({
        userEmail: context.email,
        isMonthlySubscription: context.isMonthlySubscription,
      });

    return { paymentIntentClientSecret, discountPercentage };
  },
  [SERVICES.SAVE_ONBOARDING_ANSWERS]: async (context: ContextType) => {
    if (!context.email) {
      throw new Error('Email is required to save onboarding answers');
    }

    if (!context.topic) {
      throw new Error('Topic is required to save onboarding answers');
    }
    if (!context.goal) {
      throw new Error('Goal is required to save onboarding answers');
    }
    if (!context.role) {
      throw new Error('Role is required to save onboarding answers');
    }
    if (!context.applications) {
      throw new Error('Applications is required to save onboarding answers');
    }

    const interestedSubjectAreaOptions = Object.keys(TOPICS_DATA);
    const profileOptions = Object.keys(PROFILES);
    const roleOptions = !Object.keys(ROLES).includes(context.role)
      ? [...Object.keys(ROLES), context.role]
      : Object.keys(ROLES);
    const goalOptions = !Object.keys(GOALS).includes(context.goal)
      ? [...Object.keys(GOALS), context.goal]
      : Object.keys(GOALS);
    const applicationOptions = Object.values(APPLICATIONS).map((a) => a.value);

    const selectedApplications = context.applications;
    const theoryOptions = Object.values(THEORY_PREFERENCE_OPTIONS).map(
      (o) => o.value
    );

    const isCareerOptimizer =
      Object.values(GOALS).find((g) => g.value === context.goal)?.category ===
      GOAL_CATEGORIES.OPTIMIZE;

    const topicKnowledgeOptions = [
      context.topic,
      ...context.secondaryTopics,
    ].map((t) =>
      TOPICS_DATA[t].placementTest.options.map((option) => option.value)
    );

    const timeEffortOptions = Object.values(TIME_EFFORT).map(
      (timeEffort) => timeEffort.value
    );
    const onboardingSteps: OnboardQuestion[] = [
      {
        id: 'prosumer-ask-primary-topic-subject-interest',
        type: 'single-select',
        title: 'Which are you interested in?',
        answer: interestedSubjectAreaOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: value === context.topic,
          };
        }),
        options: interestedSubjectAreaOptions,
        isAnswered: true,
      },
      {
        id: 'prosumer-ask-goal',
        type: 'single-select',
        title: 'What is your goal?',
        answer: goalOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: value === context.goal,
          };
        }),
        options: goalOptions,
        isAnswered: true,
      },
      {
        id: 'prosumer-ask-profile',
        type: 'single-select',
        title: 'What describes you best?',
        answer: profileOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: value === context.profile,
          };
        }),
        options: profileOptions,
        isAnswered: !!context.profile,
      },
      {
        id: 'prosumer-ask-role',
        type: 'single-select',
        title: isCareerOptimizer
          ? 'What describes you best now?'
          : 'What role are you aiming for?',
        answer: roleOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: value === context.role,
          };
        }),
        options: roleOptions,
        isAnswered: true,
      },
      {
        id: 'prosumer-ask-applications',
        type: 'multi-select',
        title: `What applications of ${context.topic} you interested in?`,
        answer: applicationOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: selectedApplications.includes(value),
          };
        }),
        options: applicationOptions,
        isAnswered: true,
      },
      {
        id: 'prosumer-ask-secondary-topic-subject-interest',
        type: 'multi-select',
        title: 'What secondary topics are you interested in?',
        answer: interestedSubjectAreaOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: context.secondaryTopics.includes(value),
          };
        }),
        options: interestedSubjectAreaOptions,
        isAnswered: context.secondaryTopics.length > 0,
      },
      ...topicKnowledgeOptions.map((options, topicIndex) => {
        return {
          id: 'prosumer-ask-module-knowledge',
          type: 'multi-select' as const,
          title: `What do you know about ${
            topicIndex == 0
              ? context.topic
              : context.secondaryTopics[topicIndex - 1]
          }?`,
          answer: options.map((value, optionInd) => {
            return {
              index: optionInd,
              option: value,
              isSelected: context.knowledgeOfTopics[topicIndex] === value,
            };
          }),
          options: options,
          isAnswered: true,
        };
      }),
      {
        id: 'prosumer-ask-theory-preference',
        type: 'single-select',
        title: 'Do you prefer starting from theory or practice?',
        answer: theoryOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: value === context.theoryPreference,
          };
        }),
        options: theoryOptions,
        isAnswered: !!context.theoryPreference,
      },
      {
        id: 'prosumer-ask-time-effort',
        type: 'single-select',
        title: 'How much time can you dedicate to learning?',
        answer: timeEffortOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: value === context.timeEffort,
          };
        }),
        options: timeEffortOptions,
        isAnswered: true,
      },
    ];

    const onboardingResult = await saveSoloOnboarding({
      email: context.email,
      onboardingSteps: onboardingSteps,
      partnerSignupCode: context.partnerCode,
      projectSlug: context.projectSlug,
    });

    return onboardingResult;
  },
  [SERVICES.CHECK_EMAIL_AVAILABILITY]: async (context: ContextType) => {
    if (!context.email) {
      throw new Error('Email is required to check email availability');
    }

    return await checkEmailAvailability({ email: context.email });
  },
};
