import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  CenteredContainer,
  CTAButton,
  Header,
  HighlightSpan,
  ReadableContainer,
  Label,
  CheckIcon,
} from './common';
import * as Analytics from '../../../analytics';
import styled from 'styled-components';

import { LearningPlanUnit, PlanExplanationPayload } from '../../../hydra';
import LearningPlanUnitCard from '../../Home/LearningPlanCard/LearningPlanUnitCard';

const LearningPlanWrapper = styled.div`
  width: 730px;
  overflow-x: hidden;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BulletWrapper = styled.div``;
const BulletLabel = styled(Label)`
  margin: 10px;
`;

export default function PersonalizedPlan() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const { learningPlan, planExplanation } = state.context;

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_PERSONALIZED_PLAN,
    state.context
  );

  if (!learningPlan) {
    throw new Error('Learning plan is not defined');
  }

  return (
    <PersonalizedPlanContent
      learningPlan={learningPlan}
      planExplanation={planExplanation}
      onNext={() => send(EVENTS.CONTINUE_PERSONALIZED_PLAN)}
    />
  );
}

export function PersonalizedPlanContent({
  learningPlan,
  planExplanation,
  onNext,
}: {
  learningPlan: LearningPlanUnit[];
  planExplanation?: PlanExplanationPayload | null;
  onNext?: () => void;
}) {
  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>
          Your <HighlightSpan>personalized plan</HighlightSpan> is ready!
        </Header>
        {planExplanation && <Label>{planExplanation.subtitle}</Label>}
        <LearningPlanWrapper>
          {learningPlan.map((unit, ind) => (
            <LearningPlanUnitCard
              key={unit.slug}
              learningUnit={unit}
              index={ind + 1}
              numItems={learningPlan.length}
              preview={true}
            />
          ))}
        </LearningPlanWrapper>
        <BulletWrapper>
          {planExplanation &&
            planExplanation.bullets.map((b) => (
              <BulletLabel>
                <CheckIcon className="fas fa-check" />
                {b}
              </BulletLabel>
            ))}
        </BulletWrapper>
        <Label style={{ color: 'rgba(228, 228, 231, 1)' }}>
          <i>Your plan will adapt to your activity or requests in real-time.</i>
        </Label>
        <CTAButton onClick={onNext} label="Great, continue!" />
      </CenteredContainer>
    </ReadableContainer>
  );
}
