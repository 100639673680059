import styled, { css } from 'styled-components';

import { P } from '../../elements/Text';
import { CardLabelUppercase, CardPSmall } from '../../ContentCard';
import { Tooltip } from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import {
  getAppliedProjectUrl,
  getProsumerActivityUrl,
  getProsumerDashboardUrl,
} from '../../../routing';
import {
  joinAppliedProject,
  joinProsumerContent,
  LearningPlanUnit,
} from '../../../hydra';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/use-auth';
import { useMutation } from 'react-query';

const Card = styled.div<{
  status: LearningPlanUnit['status'];
  isFirst: boolean;
  isLast: boolean;
  preview: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'completed': {
        return theme.colors.primaryBg;
      }
      case 'in-progress': {
        return theme.colors.secondaryBg;
      }
      case 'not-started': {
        return theme.colors.bgDefaultSecondary;
      }
    }
  }};

  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.bgActive};

  ${({ isFirst }) =>
    isFirst &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}

  ${({ isLast }) =>
    isLast &&
    css`
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: 0px;
    `}

  ${({ isLast, isFirst }) =>
    !isLast &&
    !isFirst &&
    css`
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
  ${({ preview }) =>
    !preview &&
    ` &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.05);
  }
  cursor: pointer;`}
`;

export const CardIcon = styled.div<{ url: string }>`
  background-image: url(${({ url }) => url});
  height: 32px;
  width: 32px;
  background-size: cover;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const NonmobileRow = styled(Row)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const CardTitle = styled(P)`
  margin-top: 0;
  flex-grow: 0;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;
  padding-bottom: 0;
`;

const CardDescription = styled(P)`
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 0;
  margin-top: 0;
`;

const TopicLabel = styled(CardLabelUppercase)`
  margin-top: 0px;
  padding-bottom: 0px;
`;

const HorizontalDivider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.bgActive};
`;

const LearnMoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  &:hover {
    & > i,
    & > p {
      transition: all 0.3s ease-in-out;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const ButtonIcon = styled.i`
  color: ${({ theme }) => theme.colors.textRegular};
  font-size: 16px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 16px;
  text-align: left;
`;
const TooltipContent = styled(CardDescription)`
  font-style: italic;
  padding: 5;
  max-width: 300px;
`;

export default function LearningPlanUnitCard({
  learningUnit,
  index,
  numItems,
  preview = false,
}: {
  learningUnit: LearningPlanUnit;
  index: number;
  numItems: number;
  preview?: boolean;
}) {
  const { status, title, subtitle, image_url, progressDescription, reasoning } =
    getCopy(learningUnit);
  const history = useHistory();
  const { getTokenSilently } = useAuth();

  const joinModuleMutation = useMutation({
    mutationFn: async () => {
      if (!(learningUnit.type === 'content-module')) {
        throw new Error('Invalid learning unit type');
      }
      try {
        const token = await getTokenSilently();
        const nextSlugs = await joinProsumerContent(token, {
          contentId: learningUnit?.pcm?.content_id,
        });
        return nextSlugs;
      } catch (err) {
        toast.error('Something went wrong');
        reportError(err);
      }
    },
  });

  const joinProjectMutation = useMutation({
    mutationFn: async () => {
      if (!(learningUnit.type === 'project')) {
        throw new Error('Invalid learning unit type');
      }
      try {
        const token = await getTokenSilently();
        await joinAppliedProject(token, {
          projectSlug: learningUnit?.project?.slug,
        });
      } catch (err) {
        toast.error('Something went wrong');
      }
    },
  });

  const onClick = async () => {
    if (learningUnit.type === 'content-module') {
      if (!learningUnit.pcm.is_joined) {
        const nextSlugs = await joinModuleMutation.mutateAsync();
        if (nextSlugs?.next_activity_slug && nextSlugs?.next_mission_slug) {
          history.push(
            getProsumerActivityUrl(
              nextSlugs.next_mission_slug,
              nextSlugs.next_activity_slug
            )
          );
        }
      } else if (
        learningUnit.pcm.next_mission_slug &&
        learningUnit.pcm.next_activity_slug
      ) {
        history.push(
          getProsumerActivityUrl(
            learningUnit.pcm.next_mission_slug,
            learningUnit.pcm.next_activity_slug
          )
        );
      } else {
        // When they have already completed the entire module
        history.push(getProsumerDashboardUrl(learningUnit.pcm.content_slug));
      }
    }
    if (learningUnit.type === 'project') {
      if (!learningUnit.project.is_joined) {
        await joinProjectMutation.mutateAsync();
      }
      history.push(getAppliedProjectUrl(learningUnit.project.slug));
    }
  };

  const isLoading =
    joinModuleMutation.isLoading || joinProjectMutation.isLoading;

  return (
    <Card
      status={status}
      isFirst={index === 1}
      isLast={index === numItems}
      onClick={isLoading || preview ? () => {} : onClick}
      preview={preview}
    >
      <Row>
        <Section>
          <CardIcon url={image_url} title={title} />
        </Section>
        <HorizontalDivider />

        <Section>
          <TopicLabel>{subtitle}</TopicLabel>
          <CardTitle>{title}</CardTitle>
        </Section>
      </Row>

      <NonmobileRow style={{ height: '100%' }}>
        <Section>
          <CardPSmall style={{ whiteSpace: 'nowrap' }}>
            {progressDescription}
          </CardPSmall>
        </Section>
        <HorizontalDivider />
        <Section>
          <Row>
            <LearnMoreWrapper>
              <ButtonIcon
                className={`fa-regular fa-circle-info info-button-${index} `}
              />
              <Tooltip anchorSelect={`.info-button-${index}`}>
                <TooltipContent>💡 {reasoning}</TooltipContent>
              </Tooltip>
            </LearnMoreWrapper>
            {!preview && (
              <LearnMoreWrapper>
                <ButtonIcon
                  className={`fa-solid fa-forward open-button-${index}`}
                />
                <Tooltip anchorSelect={`.open-button-${index}`}>
                  Continue
                </Tooltip>
              </LearnMoreWrapper>
            )}
          </Row>
        </Section>
      </NonmobileRow>
    </Card>
  );
}

const getCopy = (unit: LearningPlanUnit) => {
  if (unit.type === 'content-module') {
    return {
      title: unit.pcm.content_title,
      image_url: unit.pcm.content_image_url,
      status: unit.status,
      reasoning: unit.reasoning,
      progressDescription:
        unit.status === 'not-started'
          ? `${unit.pcm.lessons_count} lessons`
          : `${unit.pcm.completed_lessons_count ?? 0}/${
              unit.pcm.lessons_count
            } lessons`,
      subtitle: `MODULE`,
    };
  } else {
    return {
      title: unit.project.name,
      image_url: unit.project.image_url,
      status: unit.status,
      reasoning: unit.reasoning,
      progressDescription:
        unit.status === 'not-started'
          ? `${unit.project.question_count} exercises`
          : `${unit.project.completedQuestions ?? 0}/${
              unit.project.question_count
            } exercises`,
      subtitle: `PROJECT`,
    };
  }
};
