import { useActor } from '@xstate/react';
import React from 'react';
import { GOAL_CATEGORIES, GOALS } from '../config';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  CenteredContainer,
  CTAButton,
  Header,
  HighlightSpan,
  ReadableContainer,
  MainImage,
  Quote,
} from './common';
import * as Analytics from '../../../analytics';

const getQuote = (goal: string) => {
  if (goal === GOALS.EXPLORE_TEAMS.value) {
    return {
      text: 'It’s essential that our whole team is comfortable with our tooling and data. Without Enki we wouldn’t have been able to achieve this.',
      author: 'Matt',
      position: 'Head of Data @ Jackpocket',
      profileImage:
        'https://img.enkipro.com/14a6272632b66e8c12bdcf0655f77aea.jpeg',
    };
  } else if (
    Object.values(GOALS).find((v) => v.value === goal)?.category ===
    GOAL_CATEGORIES.START
  ) {
    return {
      text: 'Enki’s killer feature is the AI tutor. When I learn or am preparing for interviews, lots of questions arise and an instant answer in good quality helps enrich my learning experience.',
      author: 'Matthias G',
      position: 'Senior Analyst',
      profileImage:
        'https://img.enkipro.com/0eedacf6e3d37b6e183c42c26a05b063.png',
    };
  } else {
    return {
      text: 'Enki unlocked a superpower for me. I’m now saving hours of time every week both on my end, and for my team.',
      author: 'Molly',
      position: 'Senior Manager @ Kiavi',
      profileImage:
        'https://img.enkipro.com/4b9e5fec522e1b5d7175dd1bc1136a78.jpeg',
    };
  }
};

export default function PostAskRole() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const { goal, topic } = state.context;

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_POST_ASK_ROLE,
    state.context
  );

  if (!topic) {
    throw new Error('Topic is required to save onboarding answers');
  }

  if (!goal) {
    throw new Error('Goal is required to save onboarding answers');
  }

  const quote = getQuote(goal);

  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>
          Our truly customized program is perfect for{' '}
          <HighlightSpan>your profile and goal</HighlightSpan>!
        </Header>
        <MainImage
          src={'https://img.enkipro.com/1ec92bba65c153ba1736618014dfd4b8.png'}
        />
        <Quote {...quote} />
        <CTAButton
          onClick={() => send(EVENTS.CONTINUE_POST_ASK_ROLE)}
          label="Continue"
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
