import { useHistory } from 'react-router-dom';

import { PersonalizedPlanContent } from '../components/SoloOnboarding/Screens/PersonalizedPlan';
import { useLearningPlanUpsell } from '../hooks/use-hydra';
import { benefitsPath } from '../routing';
import PageWithAsyncData from '../components/PageWithAsyncData';
import { useEffect } from 'react';

export default function LearningPlanUpsell() {
  const { value, loading, error, retry } = useLearningPlanUpsell();
  const history = useHistory();
  useEffect(() => {
    if (!value?.learningPlan && !loading) {
      history.push(benefitsPath);
    }
  }, [value, loading, history]);
  return (
    <PageWithAsyncData
      isLoading={loading}
      error={error}
      retry={retry}
      className={''}
    >
      {value?.learningPlan && (
        <PersonalizedPlanContent
          learningPlan={value.learningPlan}
          planExplanation={value.planExplanation}
          onNext={() => {
            history.push(benefitsPath);
          }}
        />
      )}
    </PageWithAsyncData>
  );
}
