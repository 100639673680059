import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  PillButton,
  ProgressBar,
  ButtonsWrapper,
  CenteredContainer,
  Header,
  ReadableContainer,
  ButtonContainer,
} from './common';
import { GOAL_CATEGORIES, GOALS, ROLES } from '../config';
import { LabelUppercase } from '../../elements/Text';
import * as Analytics from '../../../analytics';

export default function AskRole() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_ROLE,
    state.context
  );

  const careerOptimizer =
    Object.values(GOALS).find((g) => g.value === state.context.goal)
      ?.category === GOAL_CATEGORIES.OPTIMIZE;

  return (
    <ReadableContainer>
      <CenteredContainer>
        <ProgressBar currentStep={2} />
        <LabelUppercase>Step 2</LabelUppercase>
        <Header>
          {careerOptimizer
            ? 'What describes you best now?'
            : 'What role are you aiming for?'}
        </Header>
        <ButtonContainer>
          <ButtonsWrapper>
            {Object.entries(ROLES).map(([key, { value, label }]) => (
              <PillButton
                key={key}
                label={label}
                isSelected={state.context.role === value}
                onClick={() => {
                  send({
                    type: EVENTS.SELECT_ROLE,
                    role: value,
                  });
                  send({
                    type: EVENTS.CONTINUE_ASK_ROLE,
                  });
                }}
              />
            ))}
          </ButtonsWrapper>
        </ButtonContainer>
      </CenteredContainer>
    </ReadableContainer>
  );
}
