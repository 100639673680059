import { useActor } from '@xstate/react';
import React, { useEffect } from 'react';
import { SoloOnboardingContext } from '../components/SoloOnboarding/context';
import AskGoal from '../components/SoloOnboarding/Screens/AskGoal';

import AskTimeEffort from '../components/SoloOnboarding/Screens/AskTimeEffort';

import PersonalizationLoader from '../components/SoloOnboarding/Screens/PersonalizationLoader';
import PostAskGoal from '../components/SoloOnboarding/Screens/PostAskGoal';
import GetResults from '../components/SoloOnboarding/Screens/GetResults';
import Results from '../components/SoloOnboarding/Screens/Results';
import Payment from '../components/SoloOnboarding/Screens/Payment';

import { STATES } from '../components/SoloOnboarding/state-machine';
import Auth from '../components/SoloOnboarding/Screens/Auth';
import Error from '../components/SoloOnboarding/Screens/Error';
import styled from 'styled-components';
import Navbar from '../components/Navbar/StaticNavbar';
import { NAVBAR_HEIGHT } from '../components/Navbar';

import Benefits from '../components/SoloOnboarding/Screens/Benefits';
import PartnerWelcome from '../components/SoloOnboarding/Screens/PartnerWelcome';
import PaymentCallback from '../components/SoloOnboarding/Screens/PaymentCallback';
import AuthConfirmEmail from '../components/SoloOnboarding/Screens/AuthConfirmEmail';
import autoAnimate from '@formkit/auto-animate';
import AuthCallback from '../components/SoloOnboarding/Screens/AuthCallback';
import { useLocation } from 'react-router-dom';
import AppUser from '../components/SoloOnboarding/Screens/AppUser';
import AskTopic from '../components/SoloOnboarding/Screens/AskTopic';

import AskRole from '../components/SoloOnboarding/Screens/AskRole';
import AskProfile from '../components/SoloOnboarding/Screens/AskProfile';
import AskApplications from '../components/SoloOnboarding/Screens/AskApplications';
import AskSecondaryTopics from '../components/SoloOnboarding/Screens/AskSecondaryTopics';
import AskTopicKnowledge from '../components/SoloOnboarding/Screens/AskTopicKnowledge';
import AskTheory from '../components/SoloOnboarding/Screens/AskTheory';
import PersonalizedPlan from '../components/SoloOnboarding/Screens/PersonalizedPlan';
import PostAskRole from '../components/SoloOnboarding/Screens/PostAskRole';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Wrapper = styled.div<{
  showBackgroundImage?: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  width: 100%;
  background: ${({ theme }) => theme.colors.bgPage};
`;

const StyledNavbar = styled(Navbar)`
  background: ${({ theme }) => theme.colors.bgPage};
  border-bottom: solid 1px ${({ theme }) => theme.colors.navigation};
`;

const easeInDuration = 500;

export default function SoloOnboarding() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [onboardingState] = useActor(onboardingService.service);
  const animatedRef = React.useRef(null);

  React.useEffect(() => {
    animatedRef.current &&
      autoAnimate(animatedRef.current, {
        duration: easeInDuration,
        easing: 'ease-in-out',
      });
  }, [animatedRef]);

  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, easeInDuration);
  }, [pathname]);

  return (
    <PageWrapper>
      <StyledNavbar />

      <Wrapper ref={animatedRef}>
        {onboardingState.matches(STATES.ASK_TOPIC) && <AskTopic />}

        {onboardingState.matches(STATES.ASK_GOAL) && <AskGoal />}
        {onboardingState.matches(STATES.POST_ASK_GOAL) && <PostAskGoal />}
        {onboardingState.matches(STATES.ASK_PROFILE) && <AskProfile />}
        {onboardingState.matches(STATES.ASK_ROLE) && <AskRole />}
        {onboardingState.matches(STATES.POST_ASK_ROLE) && <PostAskRole />}
        {onboardingState.matches(STATES.ASK_APPLICATIONS) && (
          <AskApplications />
        )}
        {onboardingState.matches(STATES.ASK_SECONDARY_TOPICS) && (
          <AskSecondaryTopics />
        )}
        {onboardingState.matches(STATES.ASK_TOPIC_KNOWLEDGE) && (
          <AskTopicKnowledge />
        )}
        {onboardingState.matches(STATES.ASK_THEORY) && <AskTheory />}
        {onboardingState.matches(STATES.ASK_TIME_EFFORT) && <AskTimeEffort />}
        {onboardingState.matches(STATES.SAVE_RESULTS) && (
          <PersonalizationLoader />
        )}
        {(onboardingState.matches(STATES.GET_RESULTS) ||
          onboardingState.matches(STATES.CHECK_EMAIL_AVAILABILITY)) && (
          <GetResults />
        )}
        {onboardingState.matches(STATES.APP_USER) && <AppUser />}
        {onboardingState.matches(STATES.RESULTS) && <Results />}
        {onboardingState.matches(STATES.PERSONALIZED_PLAN) && (
          <PersonalizedPlan />
        )}
        {onboardingState.matches(STATES.BENEFITS) && <Benefits />}
        {onboardingState.matches(STATES.PAYMENT) && <Payment />}
        {onboardingState.matches(STATES.PAYMENT_CALLBACK) && (
          <PaymentCallback />
        )}
        {onboardingState.matches(STATES.ERROR) && <Error />}
        {onboardingState.matches(STATES.AUTH) && <Auth />}
        {onboardingState.matches(STATES.AUTH_CONFIRM_EMAIL) && (
          <AuthConfirmEmail />
        )}
        {onboardingState.matches(STATES.AUTH_CALLBACK) && <AuthCallback />}
        {onboardingState.matches(STATES.PARTNER_WELCOME) && <PartnerWelcome />}
      </Wrapper>
    </PageWrapper>
  );
}
