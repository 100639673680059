import { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import CodeBlock from './CodeBlock';
import EmbeddedLinkRenderer from './EmbeddedLinkRenderer';
import LightboxImageRenderer from './LightboxImageRenderer';
import ParagraphSkipImageAndEmbed from './ParagraphSkipImageAndEmbed';
import remarkGfm from 'remark-gfm';
import TranslatableListItem from './TranslatableListItem';

const Wrapper = styled.div<{ css?: string }>`
  pre {
    border-radius: 8px;
  }
  .katex-html {
    font-size: 1rem;
  }
  ${({ css }) => css && css}
`;

import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import rehypeRaw from 'rehype-raw';
import rehypeStringify from 'rehype-stringify';
import remarkMermaid from 'remark-mermaid-plugin';

const TableWrapper = styled.div`
  margin-top: ${({ theme }) => theme.dimensions.spacing.default.top};
  padding-bottom: ${({ theme }) => theme.dimensions.spacing.default.bottom};

  overflow-x: auto;

  table {
    table-layout: auto;
    width: auto;
    min-width: ${({ theme }) => theme.dimensions.readableColumnWidth};
    padding-bottom: 0;
    margin-top: 0;
  }

  th {
    white-space: nowrap;
  }
`;

export default forwardRef(function Markdown(
  {
    md,
    className,
    components,
    style,
    css,
  }: {
    md: string;
    className?: string;
    components?: Record<string, React.ComponentType>;
    style?: Record<string, string>;
    css?: string;
  },
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <div ref={ref}>
      <Wrapper style={style} css={css} className={className}>
        <ReactMarkdown
          children={md}
          remarkPlugins={[
            remarkGfm,
            [
              remarkMath,
              {
                singleDollarTextMath: false,
              },
            ],
            remarkMermaid,
          ]}
          // @ts-expect-error - rehype plugins are not typed
          rehypePlugins={[rehypeKatex, rehypeRaw, rehypeStringify]}
          components={{
            code: CodeBlock,
            a: EmbeddedLinkRenderer,
            img: LightboxImageRenderer,
            table: (props) => (
              <TableWrapper className="table-wrapper">
                <table {...props} />
              </TableWrapper>
            ),
            p: ParagraphSkipImageAndEmbed, // avoid rendering imgs (now wrapped by div) within a paragraph which is the default
            li: TranslatableListItem,
            ...components,
          }}
        />
      </Wrapper>
    </div>
  );
});
