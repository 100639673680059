import React from 'react';
import { useHistory } from 'react-router-dom';
import { BenefitsContent } from '../components/SoloOnboarding/Screens/Benefits';
import { paymentPath } from '../routing';
import { useGetProsumerProfile } from '../hooks/use-hydra';
import Navbar from '../components/Navbar';
import styled from 'styled-components';
import PageWithAsyncData from '../components/PageWithAsyncData';
import * as Analytics from '../analytics';
import { EVENTS } from '../analytics';

const PageWrapper = styled(PageWithAsyncData)`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

export default function Benefits() {
  const history = useHistory();
  const {
    value: profileValue,
    loading: isLoadingProfile,
    error,
    retry,
  } = useGetProsumerProfile();
  const { hasAccess } = profileValue?.learnMode || {};

  React.useEffect(() => {
    if (!profileValue || hasAccess) {
      history.push('/');
    }
  }, [hasAccess, profileValue, history]);

  Analytics.useTrackViewOnMount(EVENTS.PROSUMER.UPSELL.VIEW_BENEFITS);

  return (
    <PageWrapper
      isLoading={isLoadingProfile}
      className={''}
      error={error}
      retry={retry}
    >
      <Navbar />
      <BenefitsContent
        onNext={() => {
          history.push(paymentPath);
        }}
      />
    </PageWrapper>
  );
}
