import React, { useRef } from 'react';
import { AppliedProjectStat } from '../hydra';

/*
If tags are selected, projects MUST share at least one tag with the selected tags.

If search query is present, projects MUST have at least one search term in their name or description.

If both search query and tags are present, projects MUST satisfy both conditions.

Projects are ranked by the number of search terms they match.
*/

export default function useProjectSearch({
  searchQuery,
  selectedTags,
  projects,
}: {
  searchQuery: string;
  selectedTags: string[];
  projects: AppliedProjectStat[];
}) {
  const searchTermcache = useRef(new Map<string, AppliedProjectStat[]>());

  const searchTerms = searchQuery
    .trim()
    .toLowerCase()
    .split(/,| /)
    .filter((s) => s.trim() !== '');

  const filteredProjects = React.useMemo(() => {
    const taggedProjectCandidates =
      selectedTags.length === 0
        ? projects
        : projects.filter((ap) => {
            return ap.tags.some((t) => selectedTags.includes(t.toLowerCase()));
          });
    if (searchTerms.length === 0) {
      return taggedProjectCandidates;
    }
    const unrankedProjectResults = searchTerms.map((term) => {
      const cacheKey = term + '|||' + selectedTags.join('-');
      if (searchTermcache.current.has(term)) {
        const results = searchTermcache.current.get(cacheKey);
        if (results) {
          return results;
        }
      }
      const results = taggedProjectCandidates.filter((ap) => {
        return (
          ap.name.toLowerCase().includes(term) ||
          ap.description.toLowerCase().includes(term)
        );
      });
      searchTermcache.current.set(cacheKey, results);
      return results;
    });

    const allMatchedProjects = Array.from(
      new Set(unrankedProjectResults.flat())
    );

    const rankedProjects = allMatchedProjects
      .map((ap) => {
        const rank = unrankedProjectResults.reduce((acc, results) => {
          return acc + (results.includes(ap) ? 1 : 0);
        }, 0);
        return { ap, rank };
      })
      .sort((a, b) => b.rank - a.rank)
      .map(({ ap }) => ap);

    return rankedProjects;
  }, [projects, selectedTags, searchTerms]);

  return filteredProjects;
}
