import { Helmet } from 'react-helmet-async';
import PageWithAsyncData from '../components/PageWithAsyncData';
import { useProjectsDashboard } from '../hooks/use-hydra';
import {
  getAppliedProjectUrl,
  getHomeUrl,
  getProjectsPageTitle,
  projectsDashboardPath,
} from '../routing';
import Navbar from '../components/Navbar';
import PageWrapper from '../components/PageWrapper';
import styled from 'styled-components';
import { H2 } from '../components/elements/Text';
import { AppliedProjectStat, joinAppliedProject } from '../hydra';
import { useHistory } from 'react-router-dom';
import React from 'react';
import useAuth from '../hooks/use-auth';
import { toast } from 'react-toastify';
import ContentCard, {
  CardHeader,
  CardIcon,
  CardLabelUppercase,
  CardPSmall,
} from '../components/ContentCard';
import MultiselectTags from '../components/MultiselectTags';
import { Input } from '../components/elements/Input';
import useProjectSearch from '../hooks/use-project-search';

const ScrollablePageWrapper = styled.div`
  height: calc(100vh - 56px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;

const PageContentWrapper = styled(PageWrapper)`
  max-width: 1100px;
  margin: 0 auto 82px;
`;

export default function ProjectsDashboard() {
  const projectsQuery = useProjectsDashboard();

  return (
    <PageWithAsyncData
      isLoading={projectsQuery.loading}
      error={projectsQuery.error}
      retry={projectsQuery.retry}
      className={''}
    >
      <Helmet>
        <title>{getProjectsPageTitle()}</title>
      </Helmet>
      <Navbar
        breadCrumbs={[
          {
            label: 'Home',
            url: getHomeUrl(),
          },
          {
            label: 'Projects',
            url: projectsDashboardPath,
          },
        ]}
      />
      <Contents />
    </PageWithAsyncData>
  );
}

const TAGS_TO_SHOW = [
  'python',
  'sql',
  'data visualization',
  'marketing',
  'finance',
  'sales',
  'data analysis',
  'customer support',
  'design',
  'data cleaning',
  'time series',
  'policy analysis',
];

export function Contents() {
  const projectsDashboardState = useProjectsDashboard();

  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [searchQuery, setSearchQuery] = React.useState('');

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const tags = React.useMemo(
    () => [
      ...new Set(
        projectsDashboardState.value
          ?.map((ap) => ap.tags)
          .flat()
          .map((t) => t.toLowerCase())
          .filter((t) => TAGS_TO_SHOW.includes(t))
      ),
    ],
    [projectsDashboardState.value]
  );

  const filteredProjects = useProjectSearch({
    searchQuery,
    selectedTags,
    projects: projectsDashboardState.value || [],
  });

  return (
    <ScrollablePageWrapper>
      <PageContentWrapper>
        <H2>Projects</H2>
        <Input
          placeholder="Search for projects"
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
        <MultiselectTags
          selectedOptions={selectedTags}
          options={tags}
          onChange={setSelectedTags}
          allowMultiple
        />

        {projectsDashboardState.value && (
          <div>
            <AppliedProjects appliedProjects={filteredProjects} />
          </div>
        )}
      </PageContentWrapper>
    </ScrollablePageWrapper>
  );
}

const GridContainer = styled.div`
  margin-top: 32px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));

  column-gap: 32px;
  row-gap: 32px;
`;

export function AppliedProjects({
  appliedProjects,
}: {
  appliedProjects: Array<AppliedProjectStat>;
}) {
  const history = useHistory();
  return (
    <GridContainer>
      {appliedProjects
        .filter((p) => p.is_joined)
        .map((ap) => {
          return (
            <ProjectCard
              key={ap.slug}
              project={ap}
              onCta={() => {
                history.push(getAppliedProjectUrl(ap.slug));
              }}
            />
          );
        })}
      {appliedProjects
        .filter((p) => !p.is_joined)
        .map((ap) => {
          return <UnenrolledProjectCard key={ap.slug} project={ap} />;
        })}
    </GridContainer>
  );
}

function UnenrolledProjectCard({ project }: { project: AppliedProjectStat }) {
  const [isJoining, setIsJoining] = React.useState(false);
  const { getTokenSilently } = useAuth();
  const history = useHistory();
  const onEnroll = async () => {
    setIsJoining(true);
    try {
      const token = await getTokenSilently();
      await joinAppliedProject(token, { projectSlug: project.slug });
      history.push(getAppliedProjectUrl(project.slug));
    } catch (err) {
      toast.error('Something went wrong');
    }
    setIsJoining(false);
  };
  return (
    <ProjectCard
      key={project.slug}
      project={project}
      onCta={onEnroll}
      btnIsLoading={isJoining}
    />
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const CardRow = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  flex-direction: row;
  display: flex;
`;
function ProjectCard({
  project,
  onCta,
  btnIsLoading = false,
}: {
  project: AppliedProjectStat;
  onCta: () => void;
  btnIsLoading?: boolean;
}) {
  const btnLabel = project.is_joined
    ? project.completedQuestions == project.question_count
      ? 'Review'
      : 'Continue'
    : 'Start';
  return (
    <ContentCard
      buttonProps={[
        { label: btnLabel, onClick: onCta, isDisabled: btnIsLoading },
      ]}
    >
      <Row>
        <CardIcon url={project.image_url} title={`Project: ${project.name}`} />
      </Row>
      <CardLabelUppercase>{project.topic} Project</CardLabelUppercase>
      <CardHeader>{project.name}</CardHeader>
      <CardPSmall>{project.description}</CardPSmall>
      <CardRow>
        <CardPSmall>
          {project.completedQuestions && `${project.completedQuestions}/`}
          {project.question_count} Exercises
        </CardPSmall>
      </CardRow>
    </ContentCard>
  );
}
